<template>
  <div>
    <v-form>
      <p class="mb-3">
        The nine GHS symbols are an improvement over the older NFPA fire diamond with its 4 sections
        for hazards. Which GHS symbols would occupy the NFPA sections?
      </p>

      <v-img
        src="/img/assignments/ChemUCI_1LC_Q1S_Q10_imageA.png"
        alt="image of the GHS chart and the old NFPA hazard chart"
        style="max-width: 729px"
      />

      <p v-for="(question, index) in questions" :key="index" class="mb-1">
        {{ question.text }}
        <v-select
          :key="question.input"
          v-model="inputs[question.input]"
          class="d-sm-inline-block ml-3 mr-3"
          :items="options"
          item-text="text"
          item-value="value"
          style="width: 250px"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default {
  name: 'ChemUCI_1LC_Q1S_Q10',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        inputA: null,
        inputB: null,
        inputC: null,
        inputD: null,
        inputE: null,
        inputF: null,
        inputH: null,
        inputG: null,
        inputI: null,
      },
      questions: [
        {
          text: 'A: ',
          input: 'inputA',
        },
        {
          text: 'B: ',
          input: 'inputB',
        },
        {
          text: 'C: ',
          input: 'inputC',
        },
        {
          text: 'D: ',
          input: 'inputD',
        },
        {
          text: 'E: ',
          input: 'inputE',
        },
        {
          text: 'F: ',
          input: 'inputF',
        },
        {
          text: 'G: ',
          input: 'inputG',
        },
        {
          text: 'H: ',
          input: 'inputH',
        },
        {
          text: 'I: ',
          input: 'inputI',
        },
      ],
      options: [
        {
          text: 'Health',
          value: 'health',
        },
        {
          text: 'Flammability',
          value: 'flammability',
        },
        {
          text: 'Instability',
          value: 'instability',
        },
        {
          text: 'Special Hazards',
          value: 'special',
        },
        {
          text: 'No NFPA secion',
          value: 'noSection',
        },
      ],
    };
  },
};
</script>
